import React, { useState } from 'react';

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container'
import Footer from '../components/Footer';

import { deleteWeekReport, IWeekFormData } from '../controllers/reports/week/deleteWeekReport';
import { deleteMonthReport, IMonthFormData } from '../controllers/reports/month/deleteMonthReport';

const RemoveReport = () => {

    var [isMonth, setIsMonth] = useState(false);
    var [isWeek, setIsWeek] = useState(false);
    var [church, setChurch] = useState('sede');
    var [week, setWeek] = useState(1);
    var [month, setMonth] = useState(0);
    var [year, setYear] = useState(new Date().getFullYear());

    function handleReportType (event: React.MouseEvent<HTMLButtonElement>) {

        let button = event.target as HTMLButtonElement;

        if (button.id === 'week') {
            setIsMonth(false);
            setIsWeek(true);
        } else if (button.id === 'month') {
            setIsMonth(true);
            setIsWeek(false);
        }
    }

    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurch(event.target.value);
    }

    function handleWeekIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setWeek(parseInt(event.target.value));
    }

    function handleMonthIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setMonth(parseInt(event.target.value));
    }

    function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setYear(parseInt(event.target.value));
    }

    const YearSelector = () => {
        var years = [] as Array<number>;
        var today = new Date();

        for (let year = today.getFullYear(); year >= 2021; year--) {
            years.push(year)
        }

        return (
            <select name="year" id="year" onChange={handleYearChange} value={year}>
                {
                    years.map((year: number) => {
                        return(
                            <option value={year}>{year}</option>
                        )
                    })
                }
            </select>
        )
    }

    var message: string;
    const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;
    const hideErrorMessage = () => { messageElement.style.display = 'none' };
    const showErrorMessage = () => { messageElement.style.display = 'block'};

    function deleteReport () {
        if (isWeek) {
            var weekFormData: IWeekFormData = {
                churchId: church,
                weekNumber: week,
                monthId: month,
                year: year
            }

            deleteWeekReport(weekFormData)
                .then((response) => {
                    if (response) {
                        message = `Formulário ${response._id} deletado com sucesso!`;
                        messageElement.innerText = message;
                        messageElement.style.backgroundColor = '#18b485';

                        showErrorMessage();
                        setTimeout(hideErrorMessage, 5000);
                    } else {
                        message = "Formulário não encontrado!";
                        messageElement.innerText = message;
                        messageElement.style.backgroundColor = '#e4270d';

                        showErrorMessage();
                        setTimeout(hideErrorMessage, 3000);
                    }
                })
        } else if (isMonth) {
            var monthFormData: IMonthFormData = {
                churchId: church,
                monthId: month,
                year: year
            }

            deleteMonthReport(monthFormData)
                .then((response) => {
                    if (response) {
                        message = `Formulário ${response._id} deletado com sucesso!`;
                        messageElement.innerText = message;
                        messageElement.style.backgroundColor = '#18b485';

                        showErrorMessage();
                        setTimeout(hideErrorMessage, 5000);
                    } else {
                        message = "Formulário não encontrado!";
                        messageElement.innerText = message;
                        messageElement.style.backgroundColor = '#e4270d';

                        showErrorMessage();
                        setTimeout(hideErrorMessage, 3000);
                    }
                })
        }
    }

    return (
        <React.Fragment>
            <InternalHeader/>
            <Container style={{ backgroundColor: '#F9F9F9' }}>
                <div className='reports-header' style={{ alignSelf: "unset", paddingLeft: "unset" }}>
                    <div className="title-container" style={{ justifyContent: 'center', flexDirection: 'column'}}>
                        <h2 style={{ textAlign: 'center' }}>Remover Formulários</h2>
                        <div className="selection">
                            <button id='week' onClick={handleReportType} style={ isWeek ? { backgroundColor: '#F9F9F9', border: '1px solid #ABABAB' } : {} }>Semanal</button>
                            <button id='month' onClick={handleReportType} style={ isMonth ? { backgroundColor: '#F9F9F9', border: '1px solid #ABABAB' } : {} }>Mensal</button>
                        </div>
                    </div>
                    <div id='search-container'>
                        <div className="search-wrapper">
                            <select name="church" id="church" onChange={handleChurchIdChange}>
                                <option value="sede">PIB Sede</option>
                                <option value="cohab">PIB Cohab</option>
                                <option value="saude">PIB Saúde</option>
                            </select>
                            
                            <select name="week" id="week" className={ !isWeek ? 'hidden' : '' } onChange={handleWeekIdChange}>
                                <option value="1">1ª Semana</option>
                                <option value="2">2ª Semana</option>
                                <option value="3">3ª Semana</option>
                                <option value="4">4ª Semana</option>
                                <option value="5">5ª Semana</option>
                            </select>
                        </div>

                        <div className="search-wrapper">
                            <select name="month" id="month" onChange={handleMonthIdChange}>
                                <option value="0">Janeiro</option>
                                <option value="1">Fevereiro</option>
                                <option value="2">Março</option>
                                <option value="3">Abril</option>
                                <option value="4">Maio</option>
                                <option value="5">Junho</option>
                                <option value="6">Julho</option>
                                <option value="7">Agosto</option>
                                <option value="8">Setembro</option>
                                <option value="9">Outubro</option>
                                <option value="10">Novembro</option>
                                <option value="11">Dezembro</option>
                            </select>
                        </div>

                        <div className="search-wrapper">
                            <YearSelector/>
                        </div>
                    </div>
                </div>
                <div className="reports-body" style={{ alignSelf: "unset", paddingLeft: "unset" }}>
                    <div className="new-report-wrapper" style={{ justifyContent: 'center' }}>
                        <div className="message-wrapper">
                            <span>Formulário não existe!</span>
                        </div>
                    </div>

                    <div className="new-report-wrapper delete" style={{ padding: '0' }}>
                        <button onClick={deleteReport}>
                            Apagar Relatório
                        </button>
                    </div>
                </div>
            </Container>
            <Footer/>
        </React.Fragment>
    )
}

export default RemoveReport;