import React, { useState, useMemo } from "react";

import InternalHeader from '../components/InternalHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';
import LoadingPanel from '../components/LoadingPanel';
import { ReactComponent as SmallSearchIcon } from '../assets/img/small-search.svg';

import { listEntries } from '../controllers/entries/listEntries';
import { removeEntry, IRemoveData } from '../controllers/entries/removeEntry';
import { ISearchEntryData, searchEntries } from '../controllers/entries/searchEntries';
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

function capitalize(string: string): string {
    return string && string[0].toUpperCase() + string.slice(1);
}

function formatPrice(price: number): string {
    return price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

interface IEntry {
    _id: string,
    entryId: string,
    churchId: string,
    week: string,
    tenths: number,
    offers: number,
    totalEntries: number;
    createdAt: string;
}

const ManageEntries = () => {
    var [churchId, setChurchId] = useState('sede');
    const [week, setWeek] = useState(`${new Date().getFullYear()}-W${getWeek(new Date())}`);

    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);

    function handleWeekChange(event: React.ChangeEvent<HTMLInputElement>) {
        setWeek(event.target.value);
        console.log(week)
    }

    function handleWeekChange2(event: React.ChangeEvent<HTMLSelectElement>) {
        setWeek(event.target.value);
        console.log(week)
    }
    
    function getWeek(date: Date) {
        var date = new Date(date.getTime());
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        var week1 = new Date(date.getFullYear(), 0, 4);
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    
    function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setChurchId(event.target.value);
    }

    // eslint-disable-next-line
    const InitialExpenses = useMemo(() => listEntries().then(entries => { setEntries(entries)}), entries);

    function excludeEntry(event: React.MouseEvent<HTMLElement>) {
        let button = event.target as HTMLElement;
        let entryId = button.dataset.id?.toString();

        let excludeData:IRemoveData = {
            id: entryId!
        }

        removeEntry(excludeData)
            .then(sucess => window.location.reload())
            .catch(err => console.warn(err));
    }


    const WeekSelector = () => {

        const weeks = [] as Array<string>;

        var atualWeek = getWeek(new Date());

        for (var i = 0; i < atualWeek; i++) {
            
            var weekString = `${new Date().getFullYear()}-W${atualWeek - i}`;
            weeks.push(weekString);
        }

        if (validateUserAgent() === 'iOS' || validateUserAgent() === 'Mac') {
            return (
                <select name="week" id="week" value={week} onChange={handleWeekChange2}>
                    {
                        weeks.map((week: string) => {
                            var weekNumber = week.split('W')[1]; 
                            return (
                                <option value={week}>Semana {weekNumber}</option>
                            )
                        })
                    }
                </select>
            )
        } else {
            return(
                <input type="week" name="week" id="week" value={week} onChange={handleWeekChange}/>
            )
        }
    }

    function doSearchEntries() {
        const searchData:ISearchEntryData = {
            churchId: churchId,
            week: week
        }

        const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;

        const hideErrorMessage = () => { messageElement.style.display = 'none' };
        const showErrorMessage = () => { messageElement.style.display = 'block'};

        setLoading(true);

        searchEntries(searchData)
        .then((results) => {
            if (results.length > 0) {
                setLoading(false);
                setEntries(results);
            } else {
                setLoading(false);
                showErrorMessage();
                setTimeout(hideErrorMessage, 3000);
            }
        })
        .catch((err) => {
            setLoading(false);
            console.warn(err);
        })
    }

    return (
        <React.Fragment>
            <LoadingPanel loading={loading}/>
            <InternalHeader/>
            <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
                <div className='reports-header' style={{ justifyContent: "center" }}>
                    <div className="title-container">
                        <h2>Entradas Gerais</h2>
                        <a href='/interno/entradas/nova-entrada'>➕</a>
                    </div>
                    <div id="search-container">
                        <div className="search-wrapper">
                            <select name="church" id="church" onChange={handleChurchIdChange}>
                                <option value="sede">PIB Sede</option>
                                <option value="cohab">PIB Cohab</option>
                                <option value="saude">PIB Saúde</option>
                            </select>
                            <WeekSelector/>
                        </div>

                        <div className="search-wrapper">
                            <button id='search-button' onClick={() => doSearchEntries()}>
                                Buscar
                                <SmallSearchIcon className="search-icon"/>
                            </button>
                        </div>
                    </div>
                    <div className="message-wrapper">
                        <span>Nenhuma entrada encontrada com os filtros selecionados</span>
                    </div>
                </div>
                {
                    entries.length > 0
                    ? <div className="reports-body" id='reports-body'>
                    {
                            entries.map((entry: IEntry) => {
                                var date = new Date(entry.createdAt).toJSON();
                                var parsedDate = date.split('T')[0],
                                    reparsedDate = parsedDate.split('-'),
                                    year = reparsedDate[0],
                                    month = reparsedDate[1],
                                    day = reparsedDate[2];

                                    function goToReport() {
                                        console.log(window.location.pathname);
                                        let reportUrl = window.location.protocol + "//" + window.location.host + '/relatorios/entradas/' + entry.entryId;
                                        let win = window.open(reportUrl, '_blank');
                                        win?.focus()
                                    }

                                return (
                                    <div className='report-element expense' data-id={entry.entryId} onClick={goToReport}>
                                        <div className="report-body">
                                            <div className="report-wrapper">
                                                <p>
                                                    <b>Entradas PIB {capitalize(entry.churchId)} - SEM {entry.week.split('-')[1].replace("W", "").padStart(2, "0")}/{entry.week.split('-')[0]}</b>
                                                </p>
                                                <p>
                                                    <b>Data: </b>
                                                    <span>{day}/{month}/{year}</span>
                                                </p>
                                            </div>
                                            <div className='report-wrapper'>
                                                <p>
                                                    <b>Dizimos: </b>
                                                    <span>{formatPrice(entry.tenths)}</span>
                                                </p>
                                                <p>
                                                    <b>Ofertas: </b>
                                                    <span>{formatPrice(entry.offers)}</span>
                                                </p>
                                            </div>
                                            <div className="report-wrapper">
                                                <p>
                                                    <b>Total: </b>
                                                    <span>{formatPrice(entry.totalEntries)}</span>
                                                </p>
                                            </div>
                                            <div className='report-wrapper'>
                                                <b data-id={entry._id} style={{ color: "crimson" }} onClick={excludeEntry}>Apagar</b>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                : <div className="reports-body" id='reports-body'><span>Nenhuma saída pendente à ser listada.</span></div>
                }

            </Container>
            <Footer/>
        </React.Fragment>
    )

}

export default ManageEntries;