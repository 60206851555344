import React, {useState, useMemo, useRef} from "react";
import { useParams } from "react-router-dom";
import { jsPDF } from 'jspdf';

import { IFilterData, loadEntry } from "../controllers/entries/loadEntry";
import { validateUserAgent } from '../controllers/misc/validateUserAgent';

import iconDownload from '../assets/img/download-solid-black.svg';

import "../style/showReport.scss";

interface IDecimistData {
    name: string;
    tenth: number;
}
interface IEntryData {
    _id: string;
    entryId: string;
    churchId: string;
    week: string;
    decimists: Array<Array<IDecimistData>>;
    tenths: number;
    offers: number;
    totalEntries: number;
}

function capitalize(string: string): string {
    return string && string[0].toUpperCase() + string.slice(1);
}

const ShowWeekEntry = () => {
    const pdfRef = useRef(null);
    var { entryId } = useParams();
    var [entries, setEntries] = useState([] as Array<IEntryData>);

    function getEntries(props: IFilterData) {
        loadEntry(props)
            .then((data: IEntryData) => setEntries([data]))
            .catch( err => console.log(err))
    }

    function savePDF(event: any) {

        if (validateUserAgent() === 'iOS') {
            if (event.target.id === 'buttonDownload') {
                event.target.style.display = 'none'
            } else {
                event.target.parentElement.style.display = 'none'
            }

            document.title = `Entradas Semana ${entries[0].week} - PIB ${capitalize(entries[0].churchId)}`;
            window.print();
        } else {
            var doc = new jsPDF('portrait', 'pt', 'a4');
            const content = pdfRef.current as unknown;
            
            doc.html(
                content as HTMLDivElement, 
                {
                    margin: 40,
                    html2canvas: { scale: 0.8 },
                    x: 10,
                    y: 10,
                    callback: function (doc) {
                        doc.save(`Entradas Semana ${entries[0].week} - PIB ${capitalize(entries[0].churchId)}`);
                        //window.close();
                    }
                }
            );
        }
        
    }

    function autoPrintReport() {
        //setTimeout(savePDF, 1000);
    }

    var searchData = { entryId: entryId } as IFilterData;

    // eslint-disable-next-line
    var searchEntry = useMemo(() => getEntries(searchData), entries)
    document.addEventListener('DOMContentLoaded', () => searchEntry);

    return (
        <React.Fragment>
            {
                entries.map((entry: IEntryData) => {
                    return (
                        <React.Fragment>
                            <button id="buttonDownload" onClick={(event) => savePDF(event)} style={{ border: 'none', background: '#00B649', borderRadius: '5px', padding: '10px', margin: '15px', position: 'absolute', right: '15px', bottom: '15px' }}>
                                <img src={iconDownload} alt="download-img" />
                            </button>
                            <div className="report-container month-container" ref={pdfRef}>
                                <div className="report-title">
                                    <h1 key={entry._id} style={{ fontFamily: "Arial" }}>Entradas Semana {entry.week} - PIB {capitalize(entry.churchId)}</h1>
                                </div>
                                <div className="report-body">
                                    <div className="report-wrapper">
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Ofertas: {entry.offers.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Dízimos: {entry.tenths.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                        <div className="row month">
                                            <table className="main-table">
                                                <thead>
                                                    <tr>
                                                        <td style={{ fontFamily: "Helvetica" }}>Dizimizta</td>
                                                        <td style={{ fontFamily: "Helvetica" }}>Valor do Dízimo</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        entry.decimists.map(( decimist ) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ fontFamily: "Helvetica" }}>{decimist[0].name}</td>
                                                                    <td style={{ fontFamily: "Helvetica" }}>{decimist[0].tenth.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row month">
                                            <span className="report-label" style={{ fontFamily: "Helvetica" }}>Total de Entradas: {entry.totalEntries.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }, autoPrintReport())
            }
        </React.Fragment>
    )

}

export default ShowWeekEntry;