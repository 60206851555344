import React, { useState, useMemo } from 'react';

import { loadInitialReports } from '../controllers/reports/loadInitialReports';
import { ISearchData, searchReports } from '../controllers/reports/searchReports';

import Header from '../components/Header';
import Container from '../components/Container';
import Footer from '../components/Footer';

import Report, { IReportProps } from '../components/Report';

import LoadingPanel from '../components/LoadingPanel';

import '../style/reports.scss';

import { ReactComponent as SearchIcon } from '../assets/img/search.svg';
import { ReactComponent as SmallSearchIcon } from '../assets/img/small-search.svg';


const Reports = () => {

  var [reports, setReports] = useState([]);
  var [churchId, setChurchId] = useState('sede');
  var [monthId, setMonthId] = useState(0);
  var [year, setYear] = useState(new Date().getFullYear());
  var [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const InitialReports = useMemo(() => loadInitialReports().then(reports => { setReports(reports); }), reports);

  window.addEventListener('load', () => InitialReports);

  var [active, setActive] = useState(false)
  const toggleSearch = () => {
    setActive(!active);
  };

  function handleChurchIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setChurchId(event.target.value);
  }

  function handleMonthIdChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setMonthId(parseInt(event.target.value));
  }

  function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setYear(parseInt(event.target.value));
  }

  const searchData:ISearchData = {
    churchId: churchId,
    monthId: monthId,
    year: year
  }

  function search() {
    const messageElement = document.querySelector('.message-wrapper span') as HTMLSpanElement;
    const hideErrorMessage = () => { messageElement.style.display = 'none' };
    const showErrorMessage = () => { messageElement.style.display = 'block'};

    setLoading(true);

    searchReports(searchData)
      .then((results) => {
        if (results.length > 0) {
          setLoading(false);
          setReports(results);
        } else {
          setLoading(false);
          showErrorMessage();
          setTimeout(hideErrorMessage, 3000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      })

  }
  
  const YearSelector = () => {
    var years = [] as Array<number>;
    var today = new Date();

    for (let year = today.getFullYear(); year >= 2021; year--) {
        years.push(year)
    }

    return (
        <select name="year" id="year" onChange={handleYearChange} value={year}>
            {
                years.map((year: number) => {
                    return(
                        <option value={year}>{year}</option>
                    )
                })
            }
        </select>
    )
  }

  return (
    <React.Fragment>
      <Header/>
      <LoadingPanel loading={loading}/>
      <Container style={{ justifyContent: 'flex-start', backgroundColor: '#F9F9F9' }}>
        <div className='reports-header'>
          <div className="title-container">
            <h2>Relatórios Financeiros</h2>
            <button onClick={toggleSearch}>
              <SearchIcon className="search-icon"/>
            </button>
          </div>

          <div className={!active ? 'hidden' : ''} id='search-container'>
            <div className="search-wrapper">
              <select name="church" id="church" onChange={handleChurchIdChange}>
                <option value="sede">PIB Sede</option>
                <option value="cohab">PIB Cohab</option>
                <option value="saude">PIB Saúde</option>
              </select>

              <select name="month" id="month" onChange={handleMonthIdChange}>
                <option value="0">Janeiro</option>
                <option value="1">Fevereiro</option>
                <option value="2">Março</option>
                <option value="3">Abril</option>
                <option value="4">Maio</option>
                <option value="5">Junho</option>
                <option value="6">Julho</option>
                <option value="7">Agosto</option>
                <option value="8">Setembro</option>
                <option value="9">Outubro</option>
                <option value="10">Novembro</option>
                <option value="11">Dezembro</option>
              </select>
            </div>
            

            <div className="search-wrapper">
             <YearSelector/>

              <button id='search-button' onClick={search}>
                Buscar
                <SmallSearchIcon className="search-icon"/>
              </button>
            </div>
          </div>

          <div className="message-wrapper">
            <span>Nenhum relatório encontrado com os filtros selecionados</span>
          </div>

        </div>
        <div className="reports-body" id='reports-body'>
          {
            reports.map((report: IReportProps) => {
              return (
                report.monthData ? <Report formId={report.formId} churchId={report.churchId} monthData={report.monthData} lastBalance={report.lastBalance} tenths={report.tenths} offers={report.offers} totalEntries={report.totalEntries} totalExpenses={report.totalExpenses} balance={report.balance} /> : <Report formId={report.formId} churchId={report.churchId} weekData={report.weekData} lastBalance={report.lastBalance} tenths={report.tenths} offers={report.offers} totalEntries={report.totalEntries} totalExpenses={report.totalExpenses} balance={report.balance} />
              );
            })
          }
        </div>
      </Container>
      <Footer/>
    </React.Fragment>
  )
}

export default Reports;